import { Box, Typography, Stack, Link, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import { IUserAccountGeneral } from '#/types/user';
import { AuthUser } from '#/types/auth';
import { fDate } from '#/utils/formatTime';

type Props = {
  service: string;
  user: AuthUser | IUserAccountGeneral;
};
const BlurryPurchaseCover = ({ service, user }: Props) => {
  const { translate } = useLocales();

  const address = `${user?.address?.street} ${user?.address?.house_number}, ${user?.address?.zip_code} ${user?.address?.city}, ${user?.address?.country}`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '0.6px solid #000000',
        paddingX: '2rem',
        paddingY: '1rem',
        gap: '2.3em',
      }}
    >
      <Stack direction="column" alignItems="center">
        <Typography variant="subtitle2">
          {String(translate(`${service}.title`))}
        </Typography>
        <Typography variant="caption">
          {String(translate(`${service}.from`))}
        </Typography>
      </Stack>
      <Stack direction="column" alignItems="center">
        <Typography variant="subtitle2">
          {`${user?.first_name} ${user?.last_name}`}
        </Typography>
        <Typography variant="caption">
          {String(translate(`${service}.born`))} {fDate(user?.date_of_birth)}
        </Typography>
        <Typography variant="caption">
          {String(
            translate(`${service}.civil_status`, {
              civil_status: translate(
                `${service}.civilStatus.${user?.civil_status}`
              ),
            })
          )}
        </Typography>
        <Typography variant="caption">
          {String(
            translate('powerOfAttorney.stepTwo.document.address', {
              address,
            })
          )}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="caption">
          {String(
            translate('powerOfAttorney.stepTwo.document.declare', {
              first_name: user?.first_name,
              last_name: user?.last_name,
            })
          )}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',

            position: 'relative',
          }}
        >
          <Box
            sx={{
              filter: 'blur(5px)',
              '-webkit-filter': 'blur(5px)',
              pointerEvents: 'none',
              userSelect: 'none',
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontWeight: 'bold',
              }}
            >
              It is a long established fact that a reader will be distracted
            </Typography>
            <Typography variant="caption">
              by the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using Content here, content
              here, making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for lorem ipsum will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </Typography>
          </Box>
          <Stack
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              paddingY: '1rem',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              alignItems: 'center',
              background: 'rgba( 255, 255, 255, 0.65 )',
              boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
              backdropFilter: 'blur( 19px )',
              '-webkit-backdrop-filter': 'blur( 19px )',
              borderRadius: '10px',
              border: '1px solid rgba( 255, 255, 255, 0.18 )',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1" textAlign="center" lineHeight={1}>
              {String(translate('global.upgradePlan'))}
            </Typography>
            <Link
              component={RouterLink}
              to="/dashboard/user/account?billing=true"
            >
              <Button size="small" variant="contained">
                <Typography variant="body2" textAlign="center">
                  {String(translate('global.choosePlan'))}
                </Typography>
              </Button>
            </Link>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default BlurryPurchaseCover;
