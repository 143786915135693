import useAuth from '#/hooks/useAuth';
import useServiceStats from '#/hooks/useServiceStats';

type ServiceKey =
  | 'living_will'
  | 'power_of_attorney'
  | 'last_will'
  | 'marriage_contract'
  | 'cohabitation_agreement';
const useDisableDownloads = (serviceKey: ServiceKey) => {
  const { user } = useAuth();
  const { downloaded } = useServiceStats();
  const isFromFinFinder =
    localStorage.getItem('gg_partner_key') === 'finfinder';

  const isLivingWill = serviceKey === 'living_will';

  const handleDisableDownloads = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { subscription_package, is_subscribed } = user;

    if (subscription_package === 'no' && !is_subscribed) {
      if (downloaded?.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (isFromFinFinder && isLivingWill) {
          return false;
        }
        return !downloaded.includes(serviceKey);
      }
    }
    if (subscription_package === 'starter') {
      if (downloaded?.length === 2) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        return !downloaded.includes(serviceKey);
      }
    }
    if (subscription_package === 'free') {
      if (downloaded?.length === 3) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        return !downloaded.includes(serviceKey);
      }
    }

    return false;
  };

  return { handleDisableDownloads };
};

export default useDisableDownloads;
