// skipcq: JS-C1003 - sentry does not expose itself as an ES Module.
import * as Yup from 'yup';
import { Card, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from '#/components/shared/hook-form';
import { useShareSignedDocument } from '#/api/servicesQueries';

type Props = {
  open: boolean;
  closeModal: () => void;
};

export default function ShareDocumentModal({ open, closeModal }: Props) {
  const { translate } = useLocales();

  const ShareSchema = Yup.object().shape({
    doctor_email: Yup.string()
      .email(String(translate('validations.user.invalidEmail')))
      .required(String(translate('validations.user.email'))),
    message: Yup.string().required(String(translate('validations.message'))),
  });

  const defaultValues = {
    doctor_email: '',
    message: '',
    automatically_show_popup: true,
    service_name: 'living_will',
  };

  const { mutateAsync: shareDocument, isLoading } =
    useShareSignedDocument('last-will');

  const methods = useForm({
    resolver: yupResolver(ShareSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: any) => {
    await shareDocument(data).then(() => {
      closeModal();
      reset(defaultValues);
    });
  };

  return (
    <DialogAnimate open={open} onClose={closeModal}>
      <Card
        sx={{
          paddingX: '1rem',
          paddingY: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
        }}
      >
        <Typography variant="subtitle1">
          {String(translate('global.shareDocument.title'))}
        </Typography>
        <Typography variant="subtitle1" fontWeight="normal">
          {String(translate('global.shareDocument.description'))}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField
              name="doctor_email"
              label={String(translate('global.formLabels.emailAddress'))}
            />
            <RHFTextField
              name="message"
              label={String(translate('global.formLabels.message'))}
              multiline
              rows={4}
            />
            <RHFCheckbox
              name="automatically_show_popup"
              label={String(translate('global.formLabels.sendAutomatically'))}
            />
            <Divider />
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                <Typography variant="body1">
                  {String(translate('global.formLabels.submit'))}
                </Typography>
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      </Card>
    </DialogAnimate>
  );
}
