import {
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import UploadSignedDoc from '#/components/shared/UploadSignedDoc';
import useLocales from '#/hooks/useLocales';
import IntroHead from '#/components/shared/IntroHead';
import ShareDocumentModal from '#/components/shared/ShareDocumentModal';

type Props = {
  serviceName: string;
  serviceInvalidateKey: string;
  documentType: string;
};

export default function MarkAsDone({
  serviceName,
  serviceInvalidateKey,
  documentType,
}: Props) {
  const [openShareModal, setOpenShareModal] = useState(false);

  const { translate } = useLocales();
  const navigate = useNavigate();

  const handleOpenShareModal = () => setOpenShareModal(true);
  const handleCloseShareModal = () => setOpenShareModal(false);

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="lg">
      <IntroHead
        title={String(
          translate('global.markAsDoneView.intro', {
            service: String(
              translate(`global.markAsDoneView.service.${documentType}`)
            ),
          })
        )}
        description=""
      />
      <Card sx={{ marginTop: 5 }}>
        <Typography variant="subtitle1" sx={{ p: 2 }}>
          {String(
            translate('global.markAsDoneView.title', {
              service: String(
                translate(`global.markAsDoneView.service.${documentType}`)
              ),
            })
          )}
        </Typography>
        <Divider />
        <Typography variant="body1" sx={{ p: 2 }}>
          {String(translate('global.markAsDoneView.helperOne'))}
        </Typography>
        <Typography variant="body1" sx={{ pb: 2, px: 2 }}>
          {String(translate('global.markAsDoneView.helperTwo'))}
        </Typography>
        <UploadSignedDoc
          serviceName={serviceName}
          serviceInvalidateKey={serviceInvalidateKey}
          documentType={documentType}
        />
        <Divider />
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ p: 2 }}
          spacing={2}
        >
          {serviceName === 'livingwill' && (
            <Button
              variant="text"
              color="primary"
              onClick={handleOpenShareModal}
            >
              <Typography variant="subtitle1" fontWeight="light">
                {String(translate('global.shareDocument.button'))}
              </Typography>
            </Button>
          )}
          <Button variant="contained" onClick={handleClose}>
            <Typography variant="subtitle1" fontWeight="light">
              {String(translate('global.close'))}
            </Typography>
          </Button>
        </Stack>
      </Card>
      <ShareDocumentModal
        open={openShareModal}
        closeModal={handleCloseShareModal}
      />
    </Container>
  );
}
